import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from '../Pages/Welcome';
import Signin from '../Pages/Signin';
import Signup from '../Pages/Signup';
import Forgot from '../Pages/Forgot';
import CheckEmail from '../Pages/CheckEmail';
import Chat from '../Pages/Chat';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import TermsConditions from '../Pages/TermsConditions';
import Pricing from '../Pages/Pricing';
import Setting from '../Pages/Setting';

const FilemindRoutes = () => {
  return (
    <Routes>
    <Route path='/' element={<Welcome />} />
    <Route path='/signin' element={<Signin />} />
    <Route path='/signup' element={<Signup />} />
    <Route path='/forgot' element={<Forgot />} />
    <Route path='/checkemail' element={<CheckEmail />} />
    <Route path='/chat' element={<Chat />} />
    <Route path='/privacypolicy' element={<PrivacyPolicy />} />
    <Route path='/termsconditions' element={<TermsConditions />} />
    <Route path='/pricing' element={<Pricing />} />
    <Route path='/setting' element={<Setting />} />
  </Routes>
  )
}

export default FilemindRoutes