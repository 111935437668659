import { Container, Grid, Typography, FormControl, Button } from '@mui/material'
import React from 'react'

// images
import  googleimg  from "../assets/images/google.png";
import loginlogo from "../assets/images/logo.png";
import brainlogo from "../assets/images/logooo.png";
import HeaderComponent from "../component/header component/HeaderComponent";
import FooterComponentTwo from "../component/footer component two/FooterComponentTwo";
import { Link } from 'react-router-dom';


const Signin = () => {
  return (
    <Grid className=' terms-conditions privacy-policy'>
      <HeaderComponent/>
     <Container>
        <Grid className='privacy-title'>
          <Typography variant='h3'>
          Terms & Conditons
          </Typography>
          <Typography variant='p'>
          Published on October 12th, 2023
          </Typography>
        </Grid>

          <Grid className='privacy-content'>
            <Grid className='privacy-box'>
              <Typography variant='h5'>1. Introduction</Typography>
              <Typography variant='span'>Welcome to Filemind’s Platform! As you have just arrived at our Terms of Service, please pause, grab a cup of coffee, and carefully read the following pages. It will take you approximately 20 minutes.<br></br>“Filemind”, “we”, “our”, “us” is Synthesia Limited along with Synthesia’s subsidiaries and affiliates, their respective directors, officers, employees, licensees, contractors, attorneys, agents, successors, and assigns.</Typography>
              <Link to="javascript:void(0)">Learn more</Link>
            </Grid>

            <Grid className='privacy-box'>
              <Typography variant='h5'>2. Personal data that we process</Typography>
              <Typography variant='span'>During your interaction with the Services or with us directly, we may process the following personal data:
                <ul>
                  <li>Identity data (such as first name, last name, username, or similar identifier)</li>
                  <li>Contact data (such as billing address, email address, and telephone numbers)</li>
                  <li>Financial data (such as bank account and payment card details)</li>
                  <li>Transaction data (such as details of pa)</li>
                </ul>
              </Typography>
            </Grid>

            <Grid className='privacy-box'>
              <Typography variant='h5'>3. Purposes for the processing of personal data</Typography>
              <Typography variant='span'>We process your personal data for the following purposes:
              <ul>
                  <li>to provide you with access to our Services,</li>
                  <li>to provide you with the Services you requested,</li>
                  <li>to create and manage your account,</li>
                  <li>to process payment for the paid Services,</li>
                  <li>to provide you with customer support,</li>
                  <li>to send you information about the Services,</li>
                  <li>to send you advertising emails,</li>
                  <li>to analyse and develop technical improvements to the Services,</li>
                  <li>to comply with our legal obligations.</li>
                </ul>
              </Typography>
            </Grid>

            <Grid className='privacy-box'>
              <Typography variant='h5'>10. Changes to the Privacy Policy</Typography>
              <Typography variant='span'>We reserve the right to change our Privacy Policy at any time. The current version of the Privacy Policy is available on the website, indicating the effective date in the heading. You are encouraged to check our Privacy Policy periodically.</Typography>
            </Grid>

          </Grid>

     </Container>
     <FooterComponentTwo/>
    </Grid>
  )
}

export default Signin