import { Container, Grid, Typography, FormControl, Button } from '@mui/material'
import React from 'react'

// images
import  googleimg  from "../assets/images/google.png";
import loginlogo from "../assets/images/logo.png";
import brainlogo from "../assets/images/logooo.png";
import tick from "../assets/images/tick.png";
import HeaderComponent from "../component/header component/HeaderComponent";
import FooterComponentTwo from "../component/footer component two/FooterComponentTwo";
import { Link } from 'react-router-dom';


const Signin = () => {
  return (
    <Grid className='pricingg'>
      <HeaderComponent/>
     <Container>

        <Grid className='pricing-title'>
          <Typography variant='h2'>Ready to Upgrade?</Typography>  
          <Typography variant='span'>Join millions of students, researchers and professionals to instantly answer questions and understand research with AI</Typography>
        </Grid>   

        <Grid className='pricing-content'>
          <Grid className='row-flx' container spacing={4} columns={12}>

            <Grid item md={4}>
                <Grid className='pricing-box'>
                  <Typography className='blue' variant='span'>FREE for Students</Typography>
                  <Typography className='price' variant='h5'>$0.00<Typography variant='span'>/month</Typography></Typography>
                  <Typography className='below-price' variant='span'>when billed monthly</Typography>
                      <Grid className='bar'>
                        <div className='bar-loader'></div>
                      </Grid>
                      <Typography className='storage' variant='span'>Using 381.07 GB of <strong>2,000</strong> GB storage space</Typography>
                    <div className='features'>
                      <ul>
                        <li><img src={tick} alt=''/> File Upload & Analysis</li>
                        <li><img src={tick} alt=''/> Common Formats (PDF, Doc/Docx)</li>
                        <li><img src={tick} alt=''/> Limited 2GB Storage</li>
                        <li><img src={tick} alt=''/> Up to 20 Uploads</li>
                      </ul>
                      <button>Free Sign up Now</button>
                    </div>
                </Grid>
            </Grid>

            <Grid item md={4}>
                <Grid className='pricing-box'>
                  <Typography className='blue' variant='span'>Basic Individual Plan</Typography>
                  <Typography className='price' variant='h5'>$9.99<Typography variant='span'>/month</Typography></Typography>
                  <Typography className='below-price' variant='span'>when billed monthly</Typography>
                      <Grid  className='plan-selector'>
                        <span>Basic Plan</span>
                        <span>Advance Plan</span>
                      </Grid>
                    <div className='features'>
                      <ul>
                        <li><img src={tick} alt=''/> 10 downloads per day</li>
                        <li><img src={tick} alt=''/> Formats PDF, PPT, Doc/Docx</li>
                        <li><img src={tick} alt=''/> Formats PDF, PPT, Doc/Docx</li>
                        <li><img src={tick} alt=''/> 40 Uploads per month</li>
                      </ul>
                      <button>Select Plan</button>
                    </div>
                </Grid>
            </Grid>

            <Grid item md={4}>
                <Grid className='pricing-box'>
                  <Typography className='blue' variant='span'>Advanced PRO Business</Typography>
                  <Typography className='price' variant='h5'>$32.99<Typography variant='span'>/month</Typography></Typography>
                  <Typography className='below-price' variant='span'>when billed monthly</Typography>
                  <Grid  className='plan-selector'>
                        <span>Business Plan</span>
                        <span>Business PRO</span>
                      </Grid>
                    <div className='features'>
                      <ul>
                        <li><img src={tick} alt=''/> Unlimited downloads per day</li>
                        <li><img src={tick} alt=''/> Access to all products</li>
                        <li><img src={tick} alt=''/> Access to new releases</li>
                        <li><img src={tick} alt=''/> 25% renewal discount</li>
                      </ul>
                     <button>Select Plan</button>
                    </div>
                </Grid>
            </Grid>

          </Grid> 

          <Grid className='row-flx' container spacing={4} columns={12}>

          <Grid item md={4}>
                <Grid className='pricing-box'>
                  <Typography className='blue' variant='span'>Basic Individual Plan</Typography>
                  <Typography className='price' variant='h5'>$9.99<Typography variant='span'>/month</Typography></Typography>
                  <Typography className='below-price' variant='span'>when billed monthly</Typography>
                  <Grid  className='plan-selector'>
                        <span>Business Plan</span>
                        <span>Business PRO</span>
                      </Grid>
                    <div className='features'>
                      <ul>
                        <li><img src={tick} alt=''/> 10 downloads per day</li>
                        <li><img src={tick} alt=''/> Formats PDF, PPT, Doc/Docx</li>
                        <li><img src={tick} alt=''/> Storage capacity up to 100 GB</li>
                        <li><img src={tick} alt=''/> 40 Uploads per month</li>
                      </ul>
                     <button>Select Plan</button>
                    </div>
                </Grid>
            </Grid>

            <Grid item md={4}>
                <Grid className='pricing-box'>
                  <Typography className='blue' variant='span'>Advanced PRO Business</Typography>
                  <Typography className='price' variant='h5'>$32.99<Typography variant='span'>/month</Typography></Typography>
                  <Typography className='below-price' variant='span'>when billed monthly</Typography>
                  <Grid  className='plan-selector'>
                        <span>Business Plan</span>
                        <span>Business PRO</span>
                      </Grid>
                    <div className='features'>
                      <ul>
                        <li><img src={tick} alt=''/> Unlimited downloads per day</li>
                        <li><img src={tick} alt=''/> Access to all products</li>
                        <li><img src={tick} alt=''/> Access to new releases</li>
                        <li><img src={tick} alt=''/> 25% renewal discount</li>
                      </ul>
                      <button>Select Plan</button>
                    </div>
                </Grid>
            </Grid>

          </Grid>

        </Grid> 

     </Container>
     <FooterComponentTwo/>
    </Grid>
  )
}

export default Signin