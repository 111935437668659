import { Container, Typography, Grid } from '@mui/material'
import React from 'react'

// Images
import logoimg from "../../assets/images/logo.png";

const FooterComponent = () => {
  return (
    <footer>
     <Container>
        <Grid container spacing={2} columns={12}>

        <Grid item xs={3}>
         <img src={logoimg} alt='' />
         <Typography variant='body'>
         <p className='coppy-right'>Copyrights © 2023 Filemind</p>
         </Typography>
        </Grid>

        <Grid item xs={3}>
         <Typography variant='h3'>
         Company
         </Typography>
         <ul>
          <li><a href=''>About</a></li>
          <li><a href=''>Investors</a></li>
          <li><a href=''>Legal & Privacy</a></li>
         </ul>
        </Grid>

        <Grid item xs={3}>
         <Typography variant='h3'>
         Features
         </Typography>
         <ul>
          <li><a href=''>Forum</a></li>
          <li><a href=''>Agencies</a></li>
          <li><a href=''>Marketplace</a></li>
          <li><a href=''>Dashboard</a></li>
         </ul>
        </Grid>

        <Grid item xs={3}>
         <Typography variant='h3'>
         Resources
         </Typography>
         <ul>
          <li><a href=''>Help & Support</a></li>
          <li><a href=''>FAQ</a></li>
         </ul>
        </Grid>

    </Grid>
     </Container>
    </footer>
  )
}

export default FooterComponent