import { Container, FormControl, Grid, Input, Typography, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'


// images
import brainlogo from "../assets/images/logooo.png";


const Forgot = () => {
  return (
    <Grid>
      
    <Grid className='forgot-sec'>
     <Container>
     <Link to="/">
     <img className='logooo' src={brainlogo} alt='' />
     </Link>
      <Grid className='forgot-box'>
        <Typography variant='h3'>Lookup your profile</Typography>
        <p>Enter the username, email address, or phone number linked to your account so we can give you a link to reset your password.</p>
        <form>
        <FormControl>
        <input type='email' required placeholder='Enter email,  phone number, or username'></input>
       </FormControl>
       <Button className='loginbtn' variant='contained'>Reset Password</Button>
       <Typography className='go-signup' variant='h6'>Back to <Link to="/checkemail">Sign In</Link> </Typography>
        </form>
      </Grid>

     </Container>
    </Grid>

    </Grid>
  )
}

export default Forgot