import { Container, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

// images
import loginlogo from "../assets/images/logo.png";
import brainlogo from "../assets/images/logooo.png";

const CheckEmail = () => {
  return (
    <Grid className='forgot-sec'>
     <Container>
     <Link to="/">
     <img className='logooo' src={brainlogo} alt='' />
     </Link>
      <Grid className='forgot-box'>
        <Typography variant='h3'>Check your email</Typography>
        <p>We emailed a link to reset your password to <span>wesley@gmail.com</span></p>
       <Button className='loginbtn' variant='contained'>Open email</Button>
       <Typography className='go-signup' variant='h6'>Back to <Link to="/signin">Sign In</Link> </Typography>
      </Grid>

     </Container>
    </Grid>
  )
}

export default CheckEmail