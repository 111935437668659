import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./Responsive.css";

import FilemindRoutes from "./routes/FilemindRoutes";


function App() {
  return (
    <>
      <BrowserRouter>
        
        <FilemindRoutes />
      
      </BrowserRouter>
    </>
  );
}

export default App;
