import { Container, Grid, Typography, FormControl, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

// images
import  googleimg  from "../assets/images/google.png";
import loginlogo from "../assets/images/logo.png";
import brainlogo from "../assets/images/logooo.png";


const Signin = () => {
  return (
    <Grid className='forgot-sec signup-sec signin-sec'>
     <Container>
     <Link to="/">
     <img className='logooo' src={brainlogo} alt='' />
     </Link>
     <Grid className='Form-blk signup-box'>
    <Typography variant='h2'>Sign in to Filemind</Typography>
    <Typography variant='p'>We’re glad you’re back</Typography>
     <form>
     <FormControl>
      <label>Email</label>
      <input type='email' required placeholder='wesley@gmail.com'></input>
     </FormControl>
     <FormControl>
      <label>Password</label>
      <input type='password' required placeholder='*******'></input>
     </FormControl>
     <Link className='forgot-link' to="/forgot">Forgot password?</Link>
     <Button className='loginbtn' variant='contained'>Sign In</Button>
     <Link className='google-btn' to=""><img src={googleimg} alt='' />Sign in with Google</Link>
     <Typography className='go-signup' variant='h6'>Don’t have an account already? <Link to="/signup">Sign up now</Link> </Typography>
     </form>
    </Grid>

     </Container>
    </Grid>
  )
}

export default Signin