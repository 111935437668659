import React from 'react'
import { Container, Grid, Typography, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HeaderComponent from "../component/header component/HeaderComponent";
import FooterComponent from "../component/footer component/FooterComponent";






// Images
import user1 from "../assets/images/user1.png";
import setting from "../assets/images/setting.png";
import send from "../assets/images/send.png";
import pause from "../assets/images/pause.png";
import user2 from "../assets/images/user2.png";
import dog from "../assets/images/dog.png";
import aiimg from "../assets/images/aiimg.png";
import hearteyes from "../assets/images/hearteyes.png";
import unasumed from "../assets/images/unasumed.png";
import profile from "../assets/images/profile.png";
import arrow from "../assets/images/arrow.png";
import upicon from "../assets/images/upload-icon.png";
import logo from "../assets/images/logo.png";
import { Link } from 'react-router-dom';



const Chat = () => {

        const [open, setOpen] = React.useState(false);
      
        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
        };   


  return (
    <Grid className='chat-main'>
        <Container>
          <Grid container columns={12}>

           <Grid item md={3}>
            <Grid className='chat-left-sec'>
             <Link to="/"><img src={logo} alt='' /></Link>
              <Grid className='drop-file-here'>
              <input type='file'/>
              <img src={upicon} alt=''/>
              </Grid>
            </Grid>
            <Grid className='chats-display'>
              <ul>
                <li>Chats</li>
                <li><img src={user1}/>Invoices2023.excel</li>
                <li className='active'><img src={user2}/>Turo.pdf</li>
                <li><img src={user2}/>Accounts.pdf</li>
              </ul>
            </Grid>
            <div className='setting'>
              <Link to='setting'><img src={setting}/>Setting</Link>
            </div>
           </Grid>
          

            <Grid item md={9}>

              <Grid className='chat-page-top'>
                <Grid className='file-back-btn'>
                    <img src={arrow} alt='' />
                    <Typography variant='span'>
                      Filemind
                    </Typography>
                </Grid>

                <Grid className='capacity-main'>
                  <Grid className='capacity-sec'>
                    <Typography variant='span'>10% capacity</Typography>
                      <div className='barr'>
                        <div className='blue-bar'></div>
                        <h5><span></span>8%</h5>
                      </div>
                  </Grid>
                </Grid>
  
                  <Grid className='drop-downn'>
                      <Grid className='drop-down-img'>
                        <img src={profile} alt=''/>
                      </Grid>
                  </Grid>
                  
              </Grid>

              <Grid className='chat-body-main'>
                <Grid className='chat-swticher'>
                  <ul>
                    <li className='active'>Chat</li>
                    {/* <li>Chart</li>
                    <li>Historical Data</li> */}
                  </ul>
                </Grid>

                <Grid className='chat-scroll'>
                  <Grid className='ai-chat'>
                    <Typography variant='span'>
                    Hello! How can I help you today?
                    </Typography>
                    <ul>
                      <li className='justnow'>Just now</li>
                      <li>Copy</li>
                      <li>Regenerate response</li>
                      <li><img src={hearteyes}/> <img src={unasumed}/></li>
                    </ul>
                  </Grid>

                  <Grid className='user-chat'>
                    <Typography variant='span'>
                    Hello! How can I help you today?
                    </Typography>
                    <ul>
                      <li className='justnow'>Just now</li>
                      <li>Edit</li>
                      <li className='profile'><img src={dog}/></li>
                    </ul>
                  </Grid>

                  <Grid className='ai-chat rightt'>
                    <Typography variant='span'></Typography>
                    <div className='dot one'></div>
                    <div className='dot two'></div>
                    <div className='dot three'></div>

                    <ul>
                      <li className='profile'><img src={aiimg}/></li>
                      <li className='pause'> <img src={pause}/> Pause generating</li>
                    </ul>
                  </Grid>

                    {/* THIS WILL BE REMOVE */}
                    <Grid className='user-chat'>
                    <Typography variant='span'>
                    Hello! How can I help you today?
                    </Typography>
                    <ul>
                      <li className='justnow'>Just now</li>
                      <li>Edit</li>
                      <li className='profile'><img src={dog}/></li>
                    </ul>
                  </Grid>

                  <Grid className='ai-chat rightt'>
                    <Typography variant='span'></Typography>
                    <div className='dot one'></div>
                    <div className='dot two'></div>
                    <div className='dot three'></div>

                    <ul>
                      <li className='profile'><img src={aiimg}/></li>
                      <li className='pause'> <img src={pause}/> Pause generating</li>
                    </ul>
                  </Grid>
                  

                        {/* * / * / * / * / */}

                    
                </Grid>

                <div className='typing-section'>
                  <input type='text' placeholder='Send a message'></input>
                  <button><img src={send} alt=''/></button>
                </div>

              </Grid>

            </Grid>

          </Grid>
        </Container>
    </Grid>
  )
}

export default Chat