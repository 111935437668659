import React from 'react'
import { Container, Grid, Typography, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HeaderComponent from "../component/header component/HeaderComponent";
import FooterComponent from "../component/footer component/FooterComponent";



// Images
import fturimg01 from "../assets/images/ftur-img01.png";
import bannerright from "../assets/images/banner-right.png";
import fturimg02 from "../assets/images/ftur-img02.png";
import codeimg from "../assets/images/code-img.jpg";
import card from "../assets/images/card.png";
import mobcover from "../assets/images/mob-cover.png";
import { Link } from 'react-router-dom';
import DropfileComponent from '../component/dropfile component/DropfileComponent';

const Welcome = () => {

        const [open, setOpen] = React.useState(false);
      
        const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
        };   

  return (
    <Grid>
    <HeaderComponent />
    <Grid className='home-bg'>
    <Grid className='banner'>
     <Container>
     <Grid container spacing={4} columns={12}>

     <Grid item xs={6}>
      <Grid className='slide-blk'>
       <Typography variant='h6'>
       NEW BETA RELEASE!
       </Typography>
       <Typography variant='h1'>
       Do 100x more <br></br>with your files
       </Typography>
       <Typography variant='body'>
       Filemind leverages AI to quickly distill complex files—whether they're financial reports, presentations, audio recordings, or videos—into concise, actionable insights in seconds
       </Typography> 
       <Grid className='slide-btn'>
        <Link className='sign-btn acc-btn1' to="/signup">Try for free</Link>
          <Link className='acc-btn acc-btn2' to="/signup">Request a Demo</Link>
       </Grid>
      </Grid>
      </Grid>

      <Grid item xs={6}>
         <Grid className='slide-blk'>
      <img src={bannerright} alt='' /> 
        </Grid>
      </Grid>

      </Grid>
     </Container>
    </Grid>

    {/* <Grid className='drop-file-sec'>
     <Container>
      <Grid className='drop-box'>
       <Typography variant='h2'>Drop files here</Typography>
       <Typography variant='body'>
       Join millions of students, researchers and professionals to instantly answer questions and understand research with AI
       </Typography>
       <Grid className='slide-btn'>
        <Link className='slide-btn1' onClick={handleClickOpen}>New Upload</Link>
        <a className='slide-btn2' href=''>Recent Uploads</a>
       </Grid>
      </Grid>
     </Container>
    </Grid> */}
    </Grid>

    <Grid className='feature-sec'>
     <Container>
       <Grid className='ftur-title'>
        <Typography variant='h6'>FEATURES</Typography>
        <Typography variant='h2'>We're a one-stop-shop for file analysis & storage</Typography>
       </Grid>
       
       <Grid container spacing={4} columns={12}>
        <Grid item xs={6}>
            <Grid className='ftur-blk'>
            <img src={fturimg01} alt='' />
             <Typography variant='h3'>Multi-file analysis</Typography>
             <Typography variant='body'>
             Batch upload several files to analyze and cross reference multiple documents to find correlations and differences
             </Typography>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid className='ftur-blk'>
            <img src={fturimg02} alt='' />
            <Typography variant='h3'>Instant Insights</Typography>
            <Typography variant='body'>
            upload a document to generate a summary, financial report, school essay, work presentation and more in seconds
            </Typography>
            </Grid>
        </Grid>
        </Grid>

     </Container>
    </Grid>

    <Grid className='make-file-sec'>
     <Container>
     <Grid className='row-flx' container spacing={2} columns={12}>
        <Grid item xs={6}>
            <Grid className='make-file-blk'>
             <Typography variant='h2'>
             We make file summarization easy
             </Typography>
             <Typography variant='body'>
             Save time with our advanced tool that automatically generates concise summaries, providing key insights without reading the entire document.
             </Typography>
             <a className='theme-btn' href=''>Upload document</a>
            </Grid>
        </Grid>
        <Grid item xs={6}>
            <Grid className='make-file-img'>
            <img src={card} alt='' />
            </Grid>
        </Grid>
        </Grid>
     </Container>
    </Grid>

    <Grid className='step-sec'>
     <Container>
     <Grid className='row-flx' container spacing={2} columns={12}>
        <Grid item xs={4}>
         <Grid className='step-blk'>
          <Typography variant='h3'>Forum</Typography>
          <Typography variant='body'>
          Whether you are seeking advice, sharing best practices, or looking to expand your network with “Filemind Social Media Forum” the perfect place to connect, learn, and grow together.
          </Typography>
         </Grid>
        </Grid>
        <Grid item xs={4}>
         <Grid className='step-blk'>
          <Typography variant='h3'>Integrations</Typography>
          <Typography variant='body'>
          Connect your preferred cloud storage platforms such as Google Drive, Dropbox, or OneDrive, allowing you to directly access and analyse files stored in your accounts.
          </Typography>
         </Grid>
        </Grid>
        <Grid item xs={4}>
         <Grid className='step-blk'>
          <Typography variant='h3'>Agencies</Typography>
          <Typography variant='body'>
          Empower your agency with Filemind, the ultimate document analysis and collaboration platform designed to elevate your workflow, boost efficiency, and drive data-driven decision-making.
          </Typography>
         </Grid>
        </Grid>
     </Grid>
     </Container>
    </Grid>

    <Grid className='calout-sec'>
     <Container>
     <Grid className='calout-blk'>
     <Grid className='row-flx' container spacing={2} columns={12}>
        <Grid item xs={4}>
            <Grid className='calout-txt-blk'>
             <Typography variant='h2'>
             Create a free account to get started.
             </Typography>
             <a className='theme-btn' href=''>Get Started</a>
            </Grid>
        </Grid>
        <Grid item xs={8}>
            <Grid className='calout-txt-img'>
            <img src={mobcover} alt='' />
            </Grid>
        </Grid>
     </Grid>
     </Grid>
     </Container>
    </Grid>

    <Dialog className='modal-drop'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DropfileComponent />
      </Dialog> 
      <FooterComponent />
    </Grid>
  )
}

export default Welcome