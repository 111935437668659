import React from 'react'
import { Container, Grid, Typography, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HeaderComponent from "../component/header component/HeaderComponent";
import FooterComponent from "../component/footer component/FooterComponent";






// Images
import user1 from "../assets/images/user1.png";
import settingimg from "../assets/images/settingimg.png";
import setting from "../assets/images/setting.png";
import send from "../assets/images/send.png";
import pause from "../assets/images/pause.png";
import user2 from "../assets/images/user2.png";
import dog from "../assets/images/dog.png";
import aiimg from "../assets/images/aiimg.png";
import hearteyes from "../assets/images/hearteyes.png";
import unasumed from "../assets/images/unasumed.png";
import profile from "../assets/images/profile.png";
import arrow from "../assets/images/arrow.png";
import upicon from "../assets/images/upload-icon.png";
import logooo from "../assets/images/logooo.png";
import { Link } from 'react-router-dom';



const Chat = () => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (

  <Grid className='setting-main'>

    <Container>
      <Grid container columns={12}>

        <Grid item md={3}>
          <Grid className='setting-left-sec'>
            <Link to="/"><img src={logooo} alt='' />Summary</Link>
            <h5>FILE UPLOAD</h5>
            <ul>
              <li><button>File Summary</button></li>
              <li><button>Payment Methods</button></li>
              <li><button>API Access</button></li>
              <li><button>Privacy</button></li>
            </ul>
            <h5>ACCOUNT</h5>
            <ul>
              <li><button>Profile</button></li>
              <li><button>Preference</button></li>
              <li><button>Password</button></li>
            </ul>

          </Grid>
        </Grid>


        <Grid item md={9}>
          <Grid className='img-upload'>
            <input type='file' />
            <img src={settingimg} alt='' />
          </Grid>
        </Grid>

      </Grid>
    </Container>
  </Grid>
  )
}


export default Chat


















