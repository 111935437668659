import { Container, Grid, Typography, FormControl, Button } from '@mui/material'
import React from 'react'

// images
import  googleimg  from "../assets/images/google.png";
import loginlogo from "../assets/images/logo.png";
import brainlogo from "../assets/images/logooo.png";
import HeaderComponent from "../component/header component/HeaderComponent";
import FooterComponentTwo from "../component/footer component two/FooterComponentTwo";
import { Link } from 'react-router-dom';


const Signin = () => {
  return (
    <Grid className='privacy-policy'>
      <HeaderComponent/>
     <Container>
        <Grid className='privacy-title'>
          <Typography variant='h3'>
              Filemind Privacy Policy
          </Typography>
          <Typography variant='p'>
              Effective as of 12th of October 2023
          </Typography>
        </Grid>

          <Grid className='privacy-content'>
            <Grid className='privacy-box'>
              <Typography variant='h5'>1. Introduction</Typography>
              <Typography variant='span' className='thankyouu'>Thank you for using Filemind!</Typography>
              <Typography variant='span'>These Terms of Use apply when you use the services of Filemind, L.L.C. or our affiliates, including our application programming interface, software, tools, developer services, data, documentation, and websites (“Services”). The Terms include our Service Terms, Sharing & Publication Policy, Usage Policies, and other documentation, guidelines, or policies we may provide in writing. By using our Services, you agree to these Terms. Our Privacy Policy explains how we collect and use personal information.</Typography>
              <Link to="javascript:void(0)">Learn more</Link>
            </Grid>

            <Grid className='privacy-box'>
              <Typography variant='h5'>2. Registration and Access</Typography>
              <Typography variant='span'>You must be at least 13 years old to use the Services. If you are under 18 you must have your parent or legal guardian's permission to use the Services. If you use the Services on behalf of another person or entity, you must have the authority to accept the Terms on their behalf. You must provide accurate and complete information to register for an account. You may not make your access credentials or account available to others outside your organization, and you are responsible for all activities that occur using your credentials.</Typography>
            </Grid>

            <Grid className='privacy-box'>
              <Typography variant='h5'>3. Usage Requirements</Typography>
              <Typography variant='span'>(a) Use of Services. You may access, and we grant you a non-exclusive right to use, the Services in accordance with these Terms. You will comply with these Terms and all applicable laws when using the Services. We and our affiliates own all rights, title, and interest in and to the Services.<br></br>
              (b) Feedback. We appreciate feedback, comments, ideas, proposals and suggestions for improvements. If you provide any of these things, we may use it without restriction or compensation to you.<br></br>
              (c) Restrictions. You may not (i) use the Services in a way that infringes, misappropriates or violates any person's rights; (ii) reverse assemble, reverse compile, decompile, translate or otherwise attempt to discover the source code or underlying components of models, algorithms, and systems of the Services (except to the extent such restrictions are contrary to applicable law); (iii) use output from the Services to develop models that compete with Filemind; (iv) except as permitted through the API, use any automated or programmatic method to extract data or output from the Services, including scraping, web harvesting, or web data extraction; (v) represent that output from the Services was human-generated when it is not or otherwise violate our Usage Policies; (vi) buy, sell, or transfer API keys without our prior consent; or (vii), send us any personal information of children under 13 or the applicable age of digital consent. You will comply with any rate limits and other requirements in our documentation. You may use Services only in geographies currently supported by</Typography>
            </Grid>

            <Grid className='privacy-box'>
              <Typography variant='h5'>4. Content</Typography>
              <Typography variant='span'>We reserve the right to change our Privacy Policy at any time. The current version of the Privacy Policy is available on the website, indicating the effective date in the heading. You are encouraged to check our Privacy Policy periodically.</Typography>
            </Grid>

          </Grid>

     </Container>
     <FooterComponentTwo/>
    </Grid>
  )
}

export default Signin