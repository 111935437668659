import { Container, Typography, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

// Images
import logoimg from "../../assets/images/logo.png";

const FooterComponent = () => {
  return (
    <footer className='footer-two'>
     <Container>
        <Grid className='coyright'>
          <Typography variant='p'>
          © 2023 Filemind. All rights reserved.
          </Typography>
          <Link to="/termsconditions">Term & Condition</Link>
          <Link to="/privacypolicy">Privacy & Policy</Link>
        </Grid>
     </Container>
    </footer>
  )
}

export default FooterComponent